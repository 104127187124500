import Axios from "axios";
let token = localStorage.getItem("accessToken") || "";

const axios = Axios.create({

  baseURL: `https://server.greenmaxcourier.com/api/v1`, 

  // baseURL:
  //   process.env.NODE_ENV === "development"
  //     ? `https://api.courier.b2gsoft.xyz/api/v1`
  //     : `https://api.courier.b2gsoft.xyz/api/v1`, // testing

  // baseURL: `http://192.168.0.242:6085/api/v1`,

  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },

});

export default axios;
